import React from "react"

import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./gatsby-theme-material-ui-top-layout/theme.js"
import { ThemeProvider as SCThemeProvider } from "styled-components"
import CssBaseline from "@material-ui/core/CssBaseline"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SCThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </SCThemeProvider>
    </ThemeProvider>
  )
}

export default Layout
