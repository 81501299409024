/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import env from "../../env-config.json"

import defaultImage from "../../static/logos/og.jpg"

function Meta({ description, meta, title, image }) {
  const metadata = env.siteMetadata

  const metaDescription = description || metadata.description

  return (
    <Helmet
      htmlAttributes={{ lang: "de" }}
      title={title}
      titleTemplate={`%s | ${metadata.title}`}
      meta={[
        ...(meta ? meta : []),
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image || defaultImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      {/* inline style elements */}
      <style type="text/css">{`
    ins.adsbygoogle[data-ad-status="unfilled"] {
      display: none !important;
    }

    `}</style>
      <script
        type="text/plain"
        data-usercentrics="Google Analytics"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${env.gtag}`}
      ></script>
      <script
        type="text/plain"
        data-usercentrics="Google Adsense"
        data-ad-client="ca-pub-9201149966528047"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      <script type="text/plain" data-usercentrics="Google Analytics">
        {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());


  gtag('config', '${env.gtag}', { 'anonymize_ip': true });

  `}
      </script>
      <script type="text/javascript">
        {`
if (!window.UC_UI_IS_RENDERED) {
  var script = document.createElement("script")
  script.setAttribute("id", "usercentrics-cmp")
  script.setAttribute("data-settings-id", "emxo4U7zG")
  script.setAttribute(
    "src",
    "https://app.usercentrics.eu/browser-ui/latest/bundle.js"
  )
  script.setAttribute("defer", true)

  document.getElementsByTagName("head")[0].appendChild(script)
}
          `}
      </script>
    </Helmet>
  )
}

export default Meta
